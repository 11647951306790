import type * as React from 'react';

import { cn } from '~/utils/cn';
import { useFormField } from './Form';
import { textVariants } from './Typography';

const Input = ({ ref, className, type, ...props }: React.ComponentPropsWithRef<'input'>) => {
  const formField = useFormField();

  return (
    <input
      type={type}
      className={cn(
        textVariants({ size: 'm' }),
        'flex h-min w-full bg-surface-on font-medium outline-hidden transition-all duration-300 ease-in-out file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:italic placeholder:text-disabled disabled:cursor-not-allowed disabled:opacity-50',
        className,
      )}
      ref={ref}
      {...props}
      {...formField.props}
      autoComplete="one-time-code"
    />
  );
};
Input.displayName = 'Input';

export { Input };
